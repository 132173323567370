import * as Yup  from 'yup';
import { rules } from '../shared/rules';

export const fullNameRegex = /^([\w]([-']?[a-z]+){0,50})+\s+([\w]([-']?[a-z]+){0,50})+$/i;

export const signUpFormSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .nullable()
    .required('Full Name is required')
    .matches(fullNameRegex, { message: 'Please provide your First and Last name' }),
  email       : rules.email,
  phoneNumber : Yup.string()
    .trim()
    .required('Phone Number is required')
    .matches(/^\+?\d+$/, 'Invalid phone number'),
  password        : rules.password,
  confirmPassword : Yup.string()
    .required('Confirm your password')
    .oneOf([ Yup.ref('password'), null ], 'Passwords must match'),
}, [ [ 'password', 'confirmPassword' ] ]);
