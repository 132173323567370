import { AxiosError }     from 'axios';
import { IErrorResponse } from '../Auth/shared/interfaces';
import { Notistack }      from '../shared/components/Notistack/Notistack';

export const handleApiErrorResponse = (error: AxiosError<IErrorResponse> | any) => {
  const errorDetails = error && error.response && error.response.data.ErrorDetails;
  if (errorDetails) {
    // Uncomment to display toasts with error messages
    Notistack.enqueueSnackbar(errorDetails?.Message, 'error');
  }
};
