import { Divider, List }                             from '@material-ui/core';
import { FC, useState }                              from 'react';
import { NavLink, useHistory }                       from 'react-router-dom';
import styled, { css }                               from 'styled-components';
import { EventDetailsModal }                         from '../../shared/components/modals/UploadEvent/EventDetailsModal';
import { EAppRoutes }                                from '../../shared/constants/constants';
import { documentTypes }                             from '../../shared/documentTypes';
import { Loader }                                    from '../../shared/enhancers/withLoader';
import { useFetchFamilyMembers }                     from '../../shared/queries/family';
import { convertToInfinitePage, datePassed }         from '../../shared/utils/commonFunctions';
import { EntityRecordAvatar }                        from '../MyFamilyCore/shared/EntityRecordAvatar';
import { SummaryListTypography, SummaryListWrapper } from '../MyFamilyCore/shared/styles';
import { TaskStatus }                                from '../MyFamilyCore/tasks/components/TaskStatus';
import { ActivityRecordPreview }                     from './ActivityRecordPreview';
import { LoadMoreFooter }                            from './LoadMoreFooter';
import { IActivity, useLoadActivitiesPage }          from './queries';

export const StyledList = styled(List)`${ ({ theme: { breakpoints } }) => css`&& {
  height: 100%;
  min-height: 220px;
  padding: 0 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .MuiListItemAvatar-root {
    margin-top: 0;
  }

  ${ breakpoints.up('sm') } {
    padding: 5px 20px;

    .MuiListItemAvatar-root {
      margin: 0 10px 0 0;
    }
  }
}` }`;

export const ListItemContent = styled.span`${ ({ theme: { breakpoints } }) => css`&& {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;

  .MuiListItemText-primary {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;

    ${ breakpoints.up('sm') } {
      font-size: 13px;
    }
  }
}` }`;

export const RecordDescription = styled.span`
  gap: 5px 25px;
  width: 100%;
  display: flex;
  margin: 5px 0;
  flex-wrap: wrap;

  & > * {
    padding-right: 10px;
    max-width: 100%;
  }
`;

export const RecordDetails = styled.span`${ ({ theme: { breakpoints } }) => css`&& {
  color: #8d91a5;
  font-size: 10px;
  font-weight: 400;
  font-family: 'Lato';

  ${ breakpoints.up('sm') } {
    font-size: 13px;
  }
}` }`;

export const ListItemWrapper = styled.span`&& {
  width: 100%;
  display: flex;
  flex-direction: column;
}`;

export const NoActivityScreenWrapper = styled.div`
  min-height: 220px;
  height: 100%;
`;

export const NoActivityScreen = styled.div`
  filter: grayscale(1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 100%;
  opacity: .4;

  svg {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
`;

const ActivityLabel = styled.span`
  align-items: center;
  margin-bottom: 5px;
  display: flex;
  gap: 10px;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderLink = styled(NavLink)`
  color: #5274be;
  font-family: 'Lato';
  font-weight: 600;
  font-size: 18px;
  display: none;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    display: flex;
  }
`;

export interface IActivityItemsProps {
  familyId?: string;
  familyMemberId?: string;
  familyItemType?: string;
  isPreviewPage?: boolean;
}

const { tasks, documents, contacts, events, checkIns } = documentTypes;

const activityIcons: Record<string, JSX.Element> = {
  [tasks.familyItemType]     : tasks.icon,
  [documents.familyItemType] : documents.icon,
  [contacts.familyItemType]  : contacts.icon,
  [events.familyItemType]    : events.icon,
  [checkIns.familyItemType]  : checkIns.icon,
};

export const ActivityItems: FC<IActivityItemsProps> = ({
  familyId,
  familyMemberId,
  familyItemType,
  isPreviewPage,
}) => {
  const history = useHistory();

  const { data: familyMembers } = useFetchFamilyMembers(familyId, { enabled: !!familyId });
  const { data: activities, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage } = useLoadActivitiesPage(
    familyId,
    familyMemberId,
    familyItemType,
    5
  );

  const [ selectedEventId, setSelectedEventId ] = useState('');

  const items = convertToInfinitePage(activities);

  const viewRecord = (activity: IActivity) => {
    if (!activity.isDeleted) {
      const type = activity?.entityType.toLowerCase();

      if (type === tasks.familyItemType) {
        history.push(`${ EAppRoutes.TasksSummary }/${ activity?.id }`);
      } else if (type === documents?.familyItemType) {
        history.push(`${ EAppRoutes.DocumentsSummary }/${ activity?.id }`);
      } else if (type === contacts?.familyItemType) {
        history.push(`${ EAppRoutes.ContactsSummary }/${ activity?.id }`);
      } else if (type === events?.familyItemType) {
        setSelectedEventId(activity?.id);
      } else if (type === checkIns.familyItemType) {
        history.push(EAppRoutes.Locate);
      }
    }
  };

  const ActivityItems = items?.map((record: IActivity, index, array) => {
    const avatar = (
      <EntityRecordAvatar
        badge={activityIcons[record.entityType.toLowerCase()]}
        member={record?.familyUser}
      />
    );

    const details = (
      <span>Added { datePassed(record.date, true) }</span>
    );

    const label = (
      <ActivityLabel>
        { record.entityType }

        <TaskStatus mb="0" status={record?.actionType} />
      </ActivityLabel>
    );

    return (
      <ActivityRecordPreview
        key={index}
        truncateMessage
        icon={avatar}
        label={label}
        details={details}
        disabled={record.isDeleted}
        familyMembers={familyMembers}
        assigned={record?.tagged || []}
        description={record.message}
        isLast={index === array.length - 1}
        onClick={() => viewRecord(record)}
      />
    );
  });

  const NoActivityPage = (
    <NoActivityScreen>
      { documents.icon }

      There is no activity
    </NoActivityScreen>
  );

  const hasPages = activities?.pages.length && activities?.pages[0]?.totalCount;

  const content = (
    <>
      { (isFetching && !isFetchingNextPage) ? <Loader /> : hasPages ? ActivityItems : NoActivityPage }
    </>
  );

  const displayViewAll = !window.location.href.includes(EAppRoutes.Activity);

  return (
    <>
      { isPreviewPage ? content : (
        <SummaryListWrapper height="100%">
          <SummaryListTypography variant="h5">
            <HeaderContent>
              Activity

              { displayViewAll && (
                <HeaderLink to={EAppRoutes.ActivityAll}>
                  View All
                </HeaderLink>
              ) }
            </HeaderContent>
          </SummaryListTypography>

          <Divider />

          <StyledList>
            { content }
          </StyledList>

          { hasNextPage && !isPreviewPage && !isFetching && (
            <LoadMoreFooter
              loading={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
            />
          ) }
        </SummaryListWrapper>
      ) }

      { selectedEventId && (
        <EventDetailsModal
          eventId={selectedEventId}
          familyId={familyId}
          open={!!selectedEventId}
          onClose={() => setSelectedEventId('')}
        />
      ) }
    </>
  );
};
