import { FC } from 'react';
import { Grid } from '@material-ui/core';
import { FastField, FormikProps } from 'formik';
import { DateRangeField } from '../../formFields/DateRangeField';
import { ModalFooterActions } from '../modals/shared/ModalFooterActions';
import { IUploadEvent } from '../modals/UploadEvent/queries';

import {
  UploadEntityFormDate,
  UploadEntityFormRow,
  UploadFormLabel,
} from '../documents/styles';

interface IAddEventModalFormProps {
  formikRef: React.RefObject<FormikProps<IUploadEvent>>;
}

export const DatePickerStatistics: FC<IAddEventModalFormProps> = ({
  formikRef,
}) => (
  <UploadEntityFormDate>
    <Grid item xs={12}>
      <UploadEntityFormRow>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <UploadFormLabel>
              Select Date
            </UploadFormLabel>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FastField
              startName="startDate"
              endName="endDate"
              component={DateRangeField}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ModalFooterActions formikRef={formikRef}>
              Apply
            </ModalFooterActions>
          </Grid>
        </Grid>
      </UploadEntityFormRow>
    </Grid>
  </UploadEntityFormDate>
);
