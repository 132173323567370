import { Box, Grid, Typography }                                          from '@material-ui/core';
import qs                                                                 from 'qs';
import React, { FC, useContext, useEffect, useMemo, useState }            from 'react';
import { useHistory }                                                     from 'react-router';
import styled                                                             from 'styled-components';
import { Layout }                                                         from '../../Layout/Layout';
import { PageBody }                                                       from '../../shared/components';
import { Notistack }                                                      from '../../shared/components/Notistack/Notistack';
import { useFetchSelectedFamily }                                         from '../../shared/components/SelectFamily/queries';
import { SelectFamily }                                                   from '../../shared/components/SelectFamily/SelectFamily';
import { isAdminRoles, queryParams }                                      from '../../shared/functions';
import { useFetchFamilies, useFetchFamilyMembers, useUpdateUserPlatform } from '../../shared/queries/family';
import { ResponsivePageTitle }                                            from '../../shared/styledComponents';
import { capitalizeFirstLetter }                                          from '../../shared/utils/commonFunctions';
import { isAndroid, isIOS }                                               from '../../shared/utils/platform';
import AuthorizationContext                                               from '../../shared/utils/withAuthorization/authorizationContext';
import { userPlatform }                                                   from '../../shared/constants/constants';
import { CHANGE_PIN_ROUTE }                                               from '../Settings/components/ManageFamilyAccount/ManageFamilyAccount';
import { CHANGE_MEMBER_PIN_ROUTE }                                        from '../Settings/components/ManageMemberAccount/ManageMemberAccount';
import { AddToFamilyCore }                                                from './components/FamilyDocuments/AddToFamilyCore';
import { FamilyMembers, FamilySection }                                   from './components/FamilyMembers/FamilyMembers';
import { FamilyMembersList }                                              from './components/FamilyMembers/FamilyMembersList';
import { MembersActivity }                                                from './components/FamilyTabs/MembersActivity';
import { TipsModal }                                                      from './components/TipsModal';
import { IFamily }                                                        from '../../Auth/shared/interfaces';

const DropdownSpacing = styled.div`
  margin-bottom: 36px;
`;

export const DashboardResponsivePageTitle = styled(ResponsivePageTitle)`
  gap: 0px;
`;

const AddToFamilyCoreSection = styled(FamilySection)`
  background-color: #fac05e;
`;

const DashboardHeader = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.13px;
`;

export const Dashboard: FC = () => {
  const { showTipsModal } = queryParams();
  const history = useHistory();
  const params = queryParams();

  const localUserPlatform = localStorage.getItem(userPlatform);

  const [ tipsModalOpen, setTipsModalOpen ] = useState(false);
  const [ platform, setPlatform ] = useState('');

  const currentUser = useContext(AuthorizationContext);
  const { data: families, isLoading: loadingFamilies, refetch: familiesRefetch } = useFetchFamilies();
  const { mutate: updatePlatform } = useUpdateUserPlatform();
  const { data: family, isLoading: loadingFamily, refetch: familyRefetch } = useFetchSelectedFamily();
  const { data: familyMembers, isLoading: loadingMembers } = useFetchFamilyMembers(family?.id, { enabled: !!family?.id });
  const familiesList = families?.map(family => family.name) || [];
  const isAdminRole = isAdminRoles(currentUser?.data?.accountType);

  const matchingFamily = families?.find((userFamily: IFamily) => userFamily.id === family?.id);

  const setSelectedMember = (id?: string) => {
    if (params.id !== id) {
      history.push({ search: qs.stringify({ ...params, id }) });
    }
  };

  const getUserPlatform = () => {
    if (isIOS) {
      setPlatform('ios');
    } else if (isAndroid) {
      setPlatform('android');
    } else setPlatform('desktop');
  };

  const updatePlatformAndSetLocalStorage = async () => {
    if (platform && !localUserPlatform) {
      try {
        await updatePlatform( platform );
        localStorage.setItem(userPlatform, platform);
      } catch (error) {
        console.error("Failed to update platform", error);
      }
    }
  };

  useEffect(() => {
    familiesRefetch();
    familyRefetch();
    getUserPlatform();
  }, []);

  useEffect(() => {
    updatePlatformAndSetLocalStorage();
  }, [ platform, localUserPlatform ]);

  useEffect(() => {
    setTipsModalOpen(!!showTipsModal);
  }, [ families ]);

  useEffect(() => {
    if (currentUser?.data?.id && (!params.id || params.id !== currentUser?.data?.id)) {
      setSelectedMember(currentUser?.data?.id);
    }

    if (!showTipsModal && currentUser?.data?.hasPin === false) {
      Notistack.enqueueSnackbar('Your should set a PIN.', 'success');

      history.push({
        pathname : isAdminRole ? CHANGE_PIN_ROUTE : CHANGE_MEMBER_PIN_ROUTE,
        search   : qs.stringify({
          goBack: true,
        }),
      });
    }
  }, [ currentUser ]);

  const DashboardPage = (
    <>
      <DashboardResponsivePageTitle>
        Hi { capitalizeFirstLetter(currentUser?.data?.firstName) }, Welcome Back!
      </DashboardResponsivePageTitle>

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          sm={6}
          lg={7}
        >
          <FamilyMembers
            user={currentUser?.data}
            loading={loadingMembers}
            data={familyMembers}
            name={family?.name}
            isActivePayment={matchingFamily?.isActivePayment}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          lg={5}
        >
          <AddToFamilyCoreSection>
            <DashboardHeader variant="h5">Add To Family Core</DashboardHeader>

            <AddToFamilyCore
              familyId={family?.id}
              isActivePayment={matchingFamily?.isActivePayment}
            />
          </AddToFamilyCoreSection>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <PageBody>
            { familiesList.length > 1 && (
              <Grid
                item
                xs={12}
                sm={8}
                md={4}
              >
                <DropdownSpacing>
                  <SelectFamily />
                </DropdownSpacing>
              </Grid>
            ) }

            <Grid
              item
              xs={12}
            >
              <FamilyMembersList
                shaded
                disabledForMembers
                data={familyMembers}
                loading={loadingMembers}
                selected={params.id as string}
                onSelect={(id) => setSelectedMember(id)}
              />

              <Box height={36} />

              { (!params?.id || familyMembers?.find(i => i.id === params?.id)) && (
                <MembersActivity />
              ) }
            </Grid>
          </PageBody>

          <TipsModal
            open={tipsModalOpen}
            handleCancel={() => setTipsModalOpen(false)}
          />
        </Grid>
      </Grid>
    </>
  );

  return useMemo(() =>
    (<Layout loading={loadingFamilies || loadingFamily || loadingMembers || currentUser?.isLoading}>
      { DashboardPage }
    </Layout>), [ loadingMembers, loadingFamily, loadingFamilies, currentUser?.isLoading, window.location.href, familyMembers, matchingFamily ]
  );
};
