import { Divider, FormHelperText, Grid }           from '@material-ui/core';
import Hidden                                      from '@material-ui/core/Hidden';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe }                              from '@stripe/stripe-js';
import { FastField, Form, Formik, FormikHelpers }  from 'formik';
import React, { FC, useEffect, useState }          from 'react';
import { useHistory, useLocation }                 from 'react-router-dom';
import axios                                       from 'axios';
import { useQueryClient }                          from 'react-query';
import { Notistack }                               from '../../shared/components/Notistack/Notistack';
import styled                                      from 'styled-components';
import config                                      from '../../../config';
import { MuiButton }                               from '../../shared/components/MuiButton';
import { EAuthRoutes }                             from '../../shared/constants/constants';
import { states }                                  from '../../shared/constants/states';
import { SelectField }                             from '../../shared/formFields/SelectField';
import { TextInputField }                          from '../../shared/formFields/TextInputField';
import { ZipCodeInputField }                       from '../../shared/formFields/ZipCodeInputField';
import { IPaymentBillingDetails, IPaymentMethod }  from '../../shared/interfaces/stripe';
import { useGetUserAccount }                       from '../../shared/queries/user';
import { ErrorTextMessage, TextBrand, TextCoupon } from '../../shared/styledComponents';
import { isNative }                                from '../../shared/utils/platform';
import { logout }                                  from '../../shared/utils/setAuthorizationToken';
import { subscriptionName }                        from '../../utils/subscription';
import { AuthField }                               from '../shared/styles';
import { CheckoutLoader }                          from './CheckoutLoader';
import { checkOutFormSchema }                      from './validation';
import { addMonth }                                from '../../shared/functions';
import { useFetchFamilies }                        from '../../shared/queries/family';
import { fallbackPrice }                           from './Checkout';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = () => loadStripe(config.stripeKey);

const SectionHeader = styled.div<{ mb?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${ ({ mb }) => mb || '30px' };
`;

const TextSmall = styled.div`
  font-weight: 300;
  font-size: 13px;
  font-family: 'Lato';
  letter-spacing: 0.14px;
`;

const StyledDivider = styled(Divider)`&& {
  margin: 12px 0;
}`;

export const StripeCard = styled(CardElement)`&& {
  padding: 10px 15px;
  background: #e6f0fe;
  border-radius: 8px;
}`;

export const CardWrapper = styled.div`
  position: relative;
`;

export const ErrorTextMessageMobile = styled.div`
  margin-top: 20px;
  font-size: 12px;
`;

export const stripeCardOptions = {
  style: {
    base: {
      fontSize        : '16px',
      color           : '#424770',
      '::placeholder' : {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
  hidePostalCode: true,
};


interface ISignUpForm {
  coupon : string;
  address: string;
  city   : string;
  state  : string;
  zip    : string;
  email  : string;
}

interface ICheckoutForm {
  loading?: boolean;

  onSuccess(paymentMethod: IPaymentMethod): void;
}

interface ICheckoutInnerForm extends ICheckoutForm {
  elements?: any;
  stripe?: any;
}

const SETTINGS_ACCOUNT_LINK = '/settings/manage-account#/settings/manage-account';

const CheckoutInnerForm: FC<ICheckoutInnerForm> = ({ elements, stripe, loading, onSuccess }) => {
  const [ cardError, setCardError ]                 = useState('');
  const [ outsideUS, setOutsideUS ]                 = useState(false);
  const [ loadingDialogOpen, setLoadingDialogOpen ] = useState(false);
  const [ couponData, setCouponData ]               = useState<any>(null);
  const [ couponError, setCouponError ]             = useState('');
  const [ currentPayment, setCurrentPayment ]       = useState('');

  const { pathname }        = useLocation();
  const isSignUp = pathname === EAuthRoutes.Checkout;

  const { data: aboutMe }  = useGetUserAccount();
  const { data: families } = useFetchFamilies();

  const queryClient = useQueryClient();

  const history = useHistory();

  const initialValues: ISignUpForm = {
    coupon  : '',
    address : aboutMe?.address || '',
    city    : aboutMe?.city || '',
    state   : aboutMe?.state || '',
    zip     : aboutMe?.zip || '',
    email   : aboutMe?.contactEmail || '',
  };

  const handleSubmit = async (values: ISignUpForm, formikActions: FormikHelpers<ISignUpForm>) => {
    if (isNative) {
      CdvPurchase.store.get(subscriptionName)?.getOffer()?.order();
    } else {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.

      const cardElement = elements.getElement(CardElement);

      const { city, address, zip, state, email } = values;

      const billing_details: IPaymentBillingDetails = {};

      if (outsideUS) {
        billing_details.email = email;
      } else {
        billing_details.address = {
          city,
          country     : 'US',
          line1       : address,
          postal_code : zip,
          state,
        };
      }

      setLoadingDialogOpen(true);
      formikActions.setSubmitting(true);

      const { error, paymentMethod }: { error: Error; paymentMethod: IPaymentMethod } = await stripe.createPaymentMethod({
        type : 'card',
        card : cardElement,
        billing_details,
      });

      setLoadingDialogOpen(false);
      formikActions.setSubmitting(false);

      if (error) {
        setCardError(error.message);
      } else {
        setCardError('');
        onSuccess(paymentMethod);
      }

      if (values.coupon) {
        setTimeout(() => {
          axios
            .post(`api/v1/users/coupon?promoCode=${values.coupon}`)
            .then(response => {
              Notistack.enqueueSnackbar(response.data.data.message, 'success');
            })
            .catch(error => {
              const errorDetails = error?.response?.data?.ErrorDetails?.Message;
              Notistack.enqueueSnackbar('Error with promocode', 'error');
            });
        }, 5000);
      }
    }
  };

  const getEndDateCoupon = (data: any) => {
    if (data?.coupon.duration === 'once') {
      return 'for one month';
    } else if (data?.coupon.duration === 'forever') {
      return 'for unlimited months';
    } else {
      return `until ${addMonth(new Date(), data?.coupon.durationInMonths).toDateString().slice(4)}`;
    }
  };

  const getEndDateCouponNotification = (data: any) => {
    if (data?.coupon.duration === 'once') {
      return 'for 1 month';
    } else if (data?.coupon.duration === 'forever') {
      return 'for unlimited months';
    } else {
      return `for ${data?.coupon.durationInMonths} months`;
    }
  };

  queryClient.setQueryData('durationDate', getEndDateCoupon(couponData));

  const handleCancel = () => {
    if (isSignUp) {
      window.history.back();
    } else {
      history.push(SETTINGS_ACCOUNT_LINK);
    };
  };

  const handleApplyClick = (promoCode: string) => {
    axios
      .get(`/api/v1/stripe-promocodes?promoCode=${promoCode}`)
      .then(response => {
        setCouponData(response.data);
        setCouponError('');
        if (response?.data?.expiresAt > new Date().toISOString() || !response?.data?.expiresAt) {
          if (response?.data?.active) {
            setCouponData(response.data);
            setCouponError('');
            queryClient.setQueryData('couponId', response?.data?.coupon.id);
            queryClient.setQueryData('couponPercent', response?.data?.coupon.percentOff);
            queryClient.setQueryData('duration', response?.data?.coupon.duration);
            queryClient.setQueryData('durationInMonths', response?.data?.coupon.durationInMonths);
            Notistack.enqueueSnackbar(`${response?.data?.coupon.percentOff}% off discount ${getEndDateCouponNotification(response?.data)}!`, 'success');
          } else Notistack.enqueueSnackbar(`Coupon Code not valid`, 'error');
        } else{
          Notistack.enqueueSnackbar(`Coupon Code is expired`, 'error');
        }})
      .catch(error => {
        const errorDetails = error?.response?.data?.ErrorDetails?.Message;
        Notistack.enqueueSnackbar(errorDetails, 'error');
        setCouponError(errorDetails);
        setCouponData(null);
        queryClient.setQueryData('couponId', null);
        queryClient.setQueryData('couponPercent', null);
        queryClient.setQueryData('duration', null);
        queryClient.setQueryData('durationInMonths', null);
      });
  };

  const getCorrectDescription = () => {
    if (couponError) {
      return <ErrorTextMessage>{couponError}</ErrorTextMessage>;
    } else {
      if ( couponData?.expiresAt > new Date().toISOString() && couponData?.active || !couponData?.expiresAt && couponData?.active) {
        return <TextCoupon>4-week free trial then {couponData?.coupon.percentOff}% discount applied to {fallbackPrice} per month subscription {getEndDateCoupon(couponData)}</TextCoupon>;
      } else if ( couponData && !couponData?.active) {
        return <ErrorTextMessage>Coupon Code not valid</ErrorTextMessage>;
      } else if (couponData?.expiresAt < new Date().toISOString()) {
        return <ErrorTextMessage>Coupon Code is expired</ErrorTextMessage>;
      } else return <p />;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={checkOutFormSchema(outsideUS, isNative)}
      onSubmit={(value, props) => {
        handleSubmit(value, props);
      }}
    >
      { ({ errors, handleSubmit, isSubmitting, values }) => (
        <Form onSubmit={handleSubmit}>
          { !isNative && (
            <Grid
              container
              spacing={3}
            >
              { isSignUp &&
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >

                    <SectionHeader mb="5px">
                      <TextBrand>Coupon Code</TextBrand>

                      <a onClick={() => setOutsideUS(!outsideUS)}>
                        { outsideUS ? 'In' : 'Not in' } the US?
                      </a>
                    </SectionHeader>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={8}
                  >
                    <FastField
                      name="coupon"
                      component={TextInputField}
                      placeholder="Enter coupon code"
                    />
                  </Grid>

                  <Hidden only={['sm','md','lg', 'xl']}>
                    { (!!couponData || !!couponError) &&
                      <Grid
                        item
                        xs={12}
                      >
                        { getCorrectDescription() }
                      </Grid>}
                  </Hidden>

                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <MuiButton
                      fullWidth
                      type="button"
                      disabled={isSubmitting || loading}
                      onClick={() => {
                        handleApplyClick(values.coupon);
                      }}
                    >
                    Apply
                    </MuiButton>
                  </Grid>

                  <Hidden only={['xs']}>
                    {(!!couponData || !!couponError) &&
                      <Grid
                        item
                        xs={12}
                      >
                        { getCorrectDescription() }
                      </Grid>}
                  </Hidden>
                </>}

              <Grid
                item
                xs={12}
              >
                <SectionHeader mb="5px">
                  <TextBrand>Billing { !outsideUS && 'Address' }</TextBrand>

                  {!isSignUp && (
                    <a onClick={() => setOutsideUS(!outsideUS)}>
                      { outsideUS ? 'In' : 'Not in' } the US?
                    </a>
                  )}

                  <Hidden only={['sm','md','lg', 'xl']}>
                    <a onClick={() => setOutsideUS(!outsideUS)}>
                      { outsideUS ? 'In' : 'Not in' } the US?
                    </a>
                  </Hidden>
                </SectionHeader>
              </Grid>

              { outsideUS ? (
                <Grid
                  item
                  xs={12}
                >
                  <FastField
                    name="email"
                    label="Email"
                    component={TextInputField}
                  />
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                  >
                    <FastField
                      name="address"
                      label="Address"
                      component={TextInputField}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <AuthField
                      name="city"
                      label="City"
                      component={TextInputField}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <AuthField
                      name="state"
                      label="State"
                      options={states}
                      component={SelectField}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <AuthField
                      name="zip"
                      label="Zip"
                      component={ZipCodeInputField}
                    />
                  </Grid>
                </>
              ) }

              <Grid
                item
                xs={12}
              >
                <StyledDivider />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <SectionHeader mb="0">
                  <TextBrand>Payment</TextBrand>
                  <TextSmall>You will not be billed at this time. </TextSmall>
                </SectionHeader>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <CardWrapper>
                  <StripeCard
                    options={stripeCardOptions}
                    onChange={() => setCardError('')}
                  />

                  <FormHelperText error>{ cardError }</FormHelperText>
                </CardWrapper>
              </Grid>
            </Grid>
          ) }

          <MuiButton
            fullWidth
            type="submit"
            margin="35px 0 15px"
            disabled={isSubmitting || loading}
          >
            { isSignUp ? 'Subscribe' : 'Update Payment Method' }
          </MuiButton>

          <MuiButton
            fullWidth
            variant="outlined"
            margin="0"
            onClick={handleCancel}
          >
            Cancel
          </MuiButton>

          { loadingDialogOpen && (
            <CheckoutLoader
              open={loadingDialogOpen}
              onClose={() => setLoadingDialogOpen(false)}
            />
          ) }
        </Form>
      ) }
    </Formik>
  );
};

export const CheckoutForm: FC<ICheckoutForm> = ({ loading, onSuccess }) => isNative ? (
  <CheckoutInnerForm
    loading={loading}
    onSuccess={onSuccess}
  />
) : (
  <Elements stripe={stripePromise()}>
    <ElementsConsumer>
      { ({ elements, stripe }) => (
        <CheckoutInnerForm
          loading={loading}
          elements={elements}
          stripe={stripe}
          onSuccess={onSuccess}
        />
      ) }
    </ElementsConsumer>
  </Elements>
);

export default CheckoutForm;
