import { FastField }                                                                    from 'formik';
import React, { ComponentType, FC, useCallback, useEffect, useMemo, useRef, useState }  from 'react';
import styled                                                                           from 'styled-components';
import { CreateCategory }                                                               from '../components/documents/CreateCategory';
import { AlertDialog }                                                                  from '../components/modals/AlertDialog';
import { SelectField }                                                                  from '../formFields/SelectField';
import { ICategory, ISubCategory, useDeleteDocumentType, useDeleteDocumentSubCategory } from '../queries/categories';
import DeleteIcon                                                                       from '@material-ui/icons/Delete';

const DeleteEventContent = styled.div`
  line-height: 28px;
`;

const AddCategorySpacing = styled.div`
  margin: 10px;
`;

const StyledName = styled.span<{ isPinned: boolean }>`
  font-weight: 'bold';
`;

const TrashIcon = styled(DeleteIcon)`&& {
  color: #1f1e1e;
  width: 20px;
  background: none;
}`;

const EditButton = styled.button`
  margin-left: 10px;
  padding: 5px;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;

export interface ISettingsPageWithAvatarComponent {
  categoriesDropdown: JSX.Element;
  subCategoriesDropdown: JSX.Element;
}

export interface IWithCategorySelectionProps {
  familyId?: string;
  values: any;
  categories?: ICategory[];
  loading: boolean;

  setFieldValue(field: string, value: any, shouldValidate?: boolean): void;
}

const CATEGORIES = [
  'Finance',
  'General',
  'Legal',
  'Medical',
  'School',
];

const GENERAL = [
  'Airline Clubs',
  'Concerns',
  'Grocery',
  'ID',
  'Loyalty Clubs',
  'Parties',
  'Schedule',
  'Sports',
  'Text Responses',
  'Travel',
  'Vacations',
  'General',
];

const FINANCE = [
  'Banking Statements',
  'Credit Card Documents',
  'General',
];

const LEGAL = [
  'Adoption',
  'Auto Insurance',
  'Auto Registration',
  'Birth Certificate',
  'Custody Documents',
  "Driver's License",
  'Legal Fees',
  'Legal Fee',
  'Life Insurance',
  'Marriage Certificate',
  'Passport',
  'Power of Attorney',
  'Power Of Attorney',
  'SSN',
  'General',
];

const MEDICAL = [
  'Dental',
  'Dental Insurance Card',
  'Medical Insurance Card',
  'Prescriptions',
  'Reports',
  'Vaccinations',
  'Vision',
  'General',
];

const SCHOOL = [
  'School',
  'Art',
  'Grade Reports',
  'Permission Slips',
  'Resume',
  'School ID',
  'General',
];

export const WithCategorySelection = (
  Component: ComponentType<ISettingsPageWithAvatarComponent>
): FC<IWithCategorySelectionProps> => {
  const addCategoryMenuAnchorRef = useRef(null);
  const addSubCategoryMenuAnchorRef = useRef(null);

  return function _WithCategorySelection({
    values,
    setFieldValue,
    familyId,
    categories,
    loading,
  }) {
    const { mutate: deleteDocumentCategory } = useDeleteDocumentType();
    const { mutate: deleteSubDocumentCategory } = useDeleteDocumentSubCategory();

    const [ categoriesList, setCategoriesList ] = useState([] as ICategory[] | any);
    const [ subCategoriesList, setSubCategoriesList ] = useState([] as ISubCategory[] | any);
    const [ selectedCategories, setSelectedCategories ] = useState({} as ICategory | null);
    const [ selectedSubCategories, setSelectedSubCategories ] = useState({} as ISubCategory | null);

    const [ deleteCategoryModalOpen, setDeleteCategoryModalOpen ] = useState(false);
    const [ deleteSubCategoryModalOpen, setDeleteSubCategoryModalOpen ] = useState(false);

    const sortCategories = (categories: any, pinnedNames: any) => {
      const sortByLocal = (a: any, b: any) => {
        if (a.isBasic === b.isBasic) {
          return a.name.localeCompare(b.name);
        }
        return a.isBasic ? -1 : 1;
      };

      const pinned = categories
        .filter((category: ICategory) => pinnedNames.includes(category.name))
        .sort(sortByLocal);

      const others = categories
        .filter((category: ICategory) => !pinnedNames.includes(category.name))
        .sort(sortByLocal);

      return [...pinned, ...others];
    };

    const sortSubCategories = (subCategories: ISubCategory[], pinnedNames: string[]) => {
      const sortByLocal = (a: ISubCategory, b: ISubCategory) => {
        if (a.isBasic === b.isBasic) {
          return a.name.localeCompare(b.name);
        }
        return a.isBasic ? -1 : 1;
      };

      const pinned = subCategories
        .filter((item: ISubCategory) => pinnedNames.includes(item.name))
        .sort(sortByLocal);

      const others = subCategories
        .filter((item: ISubCategory) => !pinnedNames.includes(item.name))
        .sort(sortByLocal);

      return [...pinned, ...others];
    };

    const handleEditCategory = (category: ICategory) => {
      setSelectedCategories(category);
      setDeleteCategoryModalOpen(true);
    };

    const handleEditSubCategory = (category: ISubCategory) => {
      setSelectedSubCategories(category);
      setDeleteSubCategoryModalOpen(true);
    };

    const onDeleteCategory = () => {
      if(familyId && selectedCategories) {
        deleteDocumentCategory({
          categoryId: selectedCategories.id,
          familyId,
        });

        setFieldValue('categoryId', "f0223b55-3fa2-4f5e-9ee6-08d924e8ba7e");
      }
    };

    const onDeleteSubCategory = () => {
      if(familyId && selectedSubCategories) {
        deleteSubDocumentCategory({
          subСategoryId: selectedSubCategories.id,
          familyId,
        });
      }
    };

    useEffect(() => {
      if (categories && !categoriesList.length) {
        const categoriesList = categories?.map(({ id, name, isBasic }: ICategory) => ({ id, name, isBasic }));

        setCategoriesList(sortCategories(categoriesList, [...CATEGORIES]) as ICategory[] || []);

        setFieldValue('categoryId', values?.categoryId || "f0223b55-3fa2-4f5e-9ee6-08d924e8ba7e");
      }
    }, [ categories, loading ]);

    useEffect(() => {
      const categoryId = values?.categoryId;

      if (categoryId && Object.hasOwnProperty.call(values, 'subCategoryId')) {
        let subCategoriesList = categories?.find(type => type.id === categoryId)
          ?.subCategories?.map(({ id, name, isBasic }) => ({ id, name, isBasic })) || [];

        subCategoriesList = sortSubCategories(subCategoriesList, [
          ...GENERAL,
          ...FINANCE,
          ...LEGAL,
          ...MEDICAL,
          ...SCHOOL,
        ]);

        setSubCategoriesList(subCategoriesList as ISubCategory[] || []);

        const isSubCategoryInCurrentList = subCategoriesList?.find(({ id }) => id === values?.subCategoryId);
        const subCategory = (values?.subCategoryId !== undefined && !!isSubCategoryInCurrentList) ? values?.subCategoryId : '';
        setFieldValue('subCategoryId', subCategory);
      }
    }, [values?.categoryId]);

    const onTypeAdded = (data: ICategory) => {
      const isType = data?.subCategories;

      if (isType && categories) {
        const categoriesList = [...categories, data]
          .map(({ id, name, isBasic }) => ({ id, name, isBasic }))
          .sort((a, b) => a.name.localeCompare(b.name));
        setCategoriesList(categoriesList) as ICategory[] | any;
        setFieldValue('categoryId', data.id);
      } else {
        const subCategories = [...subCategoriesList, data]
          .map(({ id, name }) => ({ id, name }))
          .sort((a, b) => a.name.localeCompare(b.name));
        setSubCategoriesList(subCategories);
        setFieldValue('subCategoryId', data.id);
      }
    };

    const categoriesOptions = useCallback(() => [
      ...categoriesList.map((item: ICategory) => ({
        value : item.id,
        label : (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <StyledName isPinned={false}>{item.name}</StyledName>
            {!item.isBasic && <EditButton onClick={(event) => {
              event.stopPropagation();
              handleEditCategory(item);
            }}>
              <TrashIcon />
            </EditButton>}
          </div>
        ),
      })),
      (
        <AddCategorySpacing key={familyId}> {/* eslint-disable  react/jsx-key*/}
          <CreateCategory
            outerRef={addCategoryMenuAnchorRef}
            familyId={familyId}
            categoryId={values?.categoryId}
            onSuccess={onTypeAdded}
          />
        </AddCategorySpacing>
      ) ], [ categoriesList ]);

    const pinnedNames = [
      ...GENERAL,
      ...FINANCE,
      ...LEGAL,
      ...MEDICAL,
      ...SCHOOL,
    ];

    const subCategoriesOptions = useCallback(() => [
      ...subCategoriesList.map((item: ISubCategory) => ({
        value : item.id,
        label : (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <StyledName isPinned={false}>{item.name}</StyledName>
            {!item.isBasic && <EditButton
              style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}
              onClick={(event) => {
                event.stopPropagation();
                handleEditSubCategory(item);
              }}>
              <TrashIcon />
            </EditButton>}
          </div>
        ),
      })),
      (
        <AddCategorySpacing> {/* eslint-disable  react/jsx-key*/}
          <CreateCategory
            isSubCategory
            outerRef={addSubCategoryMenuAnchorRef}
            familyId={familyId}
            categoryId={values?.categoryId}
            onSuccess={onTypeAdded}
          />
        </AddCategorySpacing>
      ),
    ], [subCategoriesList]);

    useEffect(() => {
      const categoryId = values?.categoryId;

      if (categoryId && Object.hasOwnProperty.call(values, 'subCategoryId')) {
        let subCategoriesList = categories?.find(type => type.id === categoryId)
          ?.subCategories?.map(({ id, name, isBasic }) => ({ id, name, isBasic })) || [];

        subCategoriesList = sortSubCategories(subCategoriesList, pinnedNames);

        setSubCategoriesList(subCategoriesList as ISubCategory[] || []);

        const isSubCategoryInCurrentList = subCategoriesList?.find(({ id }) => id === values?.subCategoryId);
        const subCategory = (values?.subCategoryId !== undefined && !!isSubCategoryInCurrentList)
          ? values?.subCategoryId
          : subCategoriesList.find(({ name }) => name === 'General')?.id || '';
        setFieldValue('subCategoryId', subCategory);
      }
    }, [values?.categoryId]);

    const categoriesField = (
      <div ref={addCategoryMenuAnchorRef}>
        <FastField
          key={categoriesOptions()?.length}
          name={'categoryId'}
          options={categoriesOptions()}
          component={SelectField}
        />

        <AlertDialog
          dialogWidth="350px"
          open={deleteCategoryModalOpen}
          title="Are you sure you want to delete ?"
          content={
            <DeleteEventContent>
              If you have files in this <b>{selectedCategories?.name}</b> folder, they will be moved to General folder. Are you sure?"
            </DeleteEventContent>
          }
          handleSubmit={() => onDeleteCategory()}
          handleCancel={() => {
            setDeleteCategoryModalOpen(false);
            setSelectedCategories(null);
          }}
        />
      </div>
    );

    const subCategoriesField = (
      <div ref={addSubCategoryMenuAnchorRef}>
        <FastField
          key={subCategoriesList.length}
          name={'subCategoryId'}
          options={subCategoriesOptions()}
          component={SelectField}
        />

        <AlertDialog
          dialogWidth="350px"
          open={deleteSubCategoryModalOpen}
          title="Are you sure you want to delete ?"
          content={
            <DeleteEventContent>
              If you have files in this <b>{selectedSubCategories?.name}</b> folder, they will be moved to General folder. Are you sure?"
            </DeleteEventContent>
          }
          handleSubmit={() => onDeleteSubCategory()}
          handleCancel={() => {
            setDeleteSubCategoryModalOpen(false);
            setSelectedSubCategories(null);
          }}
        />
      </div>
    );

    return useMemo(() => (
      <Component
        categoriesDropdown={categoriesField}
        subCategoriesDropdown={subCategoriesField}
      />
    ), [ loading, categoriesList, subCategoriesList, values?.categoryId, values?.subCategoryId, deleteCategoryModalOpen, deleteCategoryModalOpen, selectedSubCategories ]);
  };
};
